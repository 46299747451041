import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { tr } from 'date-fns/locale';
import {
  NotificationsCountByInvolvedUser,
  NotificationService,
} from 'libs/notification/notification.service';
import { TrainingService } from 'libs/training/training.service';
import { User } from 'libs/user/user.model';
import { user } from 'rxfire/auth';
import {
  Observable,
  Subject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';

type ApprenticeMenuData = {
  trainingId: string;
  notifications: NotificationsCountByInvolvedUser;
  apprenticeTutors: User[];
};

@Component({
  selector: 'app-apprentice-menu',
  templateUrl: './apprentice-menu.component.html',
  styleUrls: ['./apprentice-menu.component.scss'],
})
export class ApprenticeMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() apprentice: any;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  data$: Observable<ApprenticeMenuData>;

  private destroy$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private trainingService: TrainingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      JSON.stringify(changes.tutor?.currentValue?.trainings) !==
      JSON.stringify(changes.tutor?.previousValue?.trainings)
    ) {
      console.log('APPRENTICE MENU:', changes.apprentice?.currentValue);
      this.getData();
    }
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    console.log('APPRENTICE MENU DESTROY!');
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData() {
    const trainingId = this.apprentice.trainings[0];

    const apprenticeTutors$ = this.trainingService
      .getUserTrainingById(trainingId)
      .pipe(
        map((training) =>
          training.apprentices
            .find((app) => app.id === this.apprentice.id)
            .tutors.map((tutorId) => training.users.tutors[tutorId])
        ),
        startWith(null)
      );

    const notififications$ = this.notificationService
      .getUserNotifications(this.apprentice.id, this.apprentice.role)
      .pipe(
        map(
          (userNotifications) => userNotifications[trainingId]?.involvedUsers
        ),
        takeUntil(this.destroy$),
        startWith({})
      );

    this.data$ = combineLatest([apprenticeTutors$, notififications$]).pipe(
      map(([apprenticeTutors, notifications]) => ({
        apprenticeTutors,
        trainingId,
        notifications,
      }))
    );
  }

  handleLogout() {
    this.logout.emit();
  }
}
