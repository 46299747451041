import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'libs/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'manager/home',
    loadChildren: () =>
      import('./pages/manager/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
    data: { roles: ['manager'] },
  },
  {
    path: 'tutor/home',
    loadChildren: () =>
      import('./pages/tutor/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
    data: { roles: ['tutor'] },
  },
  {
    path: 'tutor/home/:trainingId',
    loadChildren: () =>
      import('./pages/tutor/training/training.module').then(
        (m) => m.TrainingPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['tutor'] },
  },
  {
    path: ':viewer/:trainingId/profile/:role/:id',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':viewer/:trainingId/questionnaire/:type/:role/:id',
    loadChildren: () =>
      import('./pages/questionnaire/questionnaire.module').then(
        (m) => m.QuestionnairePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':viewer/:trainingId/questionnaire/:type/:role/:id/:tutorId',
    loadChildren: () =>
      import('./pages/questionnaire/questionnaire.module').then(
        (m) => m.QuestionnairePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'manager/training/:id',
    loadChildren: () =>
      import('./pages/manager/training/training.module').then(
        (m) => m.TrainingPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['manager'] },
  },
  {
    path: ':role/messenger/:trainingId/:type/:id',
    loadChildren: () =>
      import('./pages/messenger/messenger.page.module').then(
        (m) => m.MessengerPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['manager', 'tutor', 'apprentice'] },
  },
  {
    // Path to special 'pedago' groupe between one apprentice / one tutor and the managers
    // e.g. : /manager/messenger/55sa31FIrxjToRWOnML0/group/MBgOCghu60j5ZLYsYstG/rwoGEeWmF3EOt2o6EzzOQxxcHrk0/4CM3IxJ3ZxWi0SKqn6I5
    path: ':role/messenger/:trainingId/:type/:id/:apprenticeId/:tutorId',
    loadChildren: () =>
      import('./pages/messenger/messenger.page.module').then(
        (m) => m.MessengerPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: ['manager', 'tutor', 'apprentice'] },
  },
  {
    path: 'apprentice/home',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
    data: { roles: ['apprentice'] },
  },
  {
    path: 'alumni/home',
    loadChildren: () =>
      import('./pages/alumni/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
    data: { roles: ['alumni', 'manager'] },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [AuthGuard],
    data: { roles: ['apprentice', 'tutor', 'manager'] },
  },
  {
    path: 'avatar',
    loadChildren: () =>
      import('./pages/avatar/avatar.module').then((m) => m.AvatarPageModule),
    canActivate: [AuthGuard],
    data: { roles: ['manager', 'tutor', 'apprentice'] },
  },
  {
    path: ':role/avatar',
    loadChildren: () =>
      import('./pages/avatar/avatar.module').then((m) => m.AvatarPageModule),
    canActivate: [AuthGuard],
    data: { roles: ['manager', 'tutor', 'apprentice'] },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
